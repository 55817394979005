export { matchers } from './client-matchers.js';

			export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')];

			export const server_loads = [4,3,6,5];

			export const dictionary = {
	"/(sidebars)": [12,[4]],
	"/(onlyleftsidebar)/debug/modals": [7,[3]],
	"/(onlyleftsidebar)/debug/post": [8,[3]],
	"/(onlyleftsidebar)/debug/trpc": [9,[3]],
	"/(onlyleftsidebar)/debug/user": [10,[3]],
	"/login": [~18,[6]],
	"/(singlenav)/migrate/authenticate": [~15,[5]],
	"/(singlenav)/migrate/closed": [~16,[5]],
	"/(sidebars)/p/[postid]": [13,[4]],
	"/(singlenav)/secure": [17,[5]],
	"/(onlyleftsidebar)/settings": [~11,[3]],
	"/(sidebars)/u/[username]": [14,[4]]
};

			export const hooks = {
				handleError: (({ error }) => { console.error(error) }),
			};